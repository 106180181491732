import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AfsService } from '@services/afs.service';
import { AlertService } from '@services/alert.service';
import { EnvService } from '@services/env.service';
import { FunctionsService } from '@services/functions.service';
import { NavigateService } from '@services/navigate.service';
import { SettingsService } from '@settings/settings.service';

import { RequestAddConnection } from '@shared/request.interface';
import { ResourceShopifyToken } from '@shared/resources.interface';

import { AppState } from '@state/app.state';
import { UserState } from '@state/user.state';

@Injectable({ providedIn: 'root' })
export class ConnectService {

  constructor(
    private afs: AfsService,
    private alert: AlertService,
    private appState: AppState,
    private env: EnvService,
    private functions: FunctionsService,
    private navigate: NavigateService,
    private router: Router,
    private settings: SettingsService,
    private userState: UserState,
  ) { }

  public async addShopifyConnection(shop: string): Promise<string> {
    this.alert.loadingMessage(`Creating connection to Shopify...`, 3, 20);

    const token = await this.getShopifyToken(shop);
    if (!this.userState.user.uid) {
      this.alert.loadingMessage();
      return token;
    }

    let locationID = '';
    let connectionID = '';
    let unusedLocationID = '';
    let unusedConnectionID = '';
    let matches = 0;

    const settings = await this.settings.getRawSettings();
    if (settings) {
      for (const location of settings.locations) {
        for (const connection of location.connections) {
          if (connection.sourceID === 'SH' && connection.id === shop) {
            matches++;
            locationID = location.locationID;
            connectionID = connection.connectionID;
            break;
          } else if (connection.sourceID === 'SH' && !connection.id && !connection.active) {
            unusedLocationID = location.locationID;
            unusedConnectionID = connection.connectionID;
          }
        }
      }
    }

    // If duplicate connection is attempted, show error message
    if (matches > 1) {
      await this.alert.message('Duplicate Connection',
        `A connection to this Shopify account already exists.\n\n` +
        `Multiple connections to the same account would result in duplicate order data.`,
        'info', 'Continue',
      );
      this.alert.loadingMessage();

      // Find most likely duplicate connection
      for (const location of settings.locations) {
        for (const connection of location.connections) {
          if (connection.sourceID === 'SH' && connection.id !== shop && !connection.active) {
            unusedLocationID = location.locationID;
            unusedConnectionID = connection.connectionID;
          }
        }
      }

      await this.showConnection(unusedLocationID || locationID, unusedConnectionID || connectionID);
      return token;
    }

    // If matching connection isn't found, use the first inactive, unused connection -- or create a new one
    if (!locationID || !connectionID) {
      if (unusedLocationID && unusedConnectionID) {
        locationID = unusedLocationID;
        connectionID = unusedConnectionID;
      } else {
        locationID = settings.locations[0].locationID;
        connectionID = await this.settings.addConnection('SH', locationID);
      }
    }

    if (!token) {
      console.error(`Missing merchant token in connect.addShopifyConnection for ` +
        `${this.appState.clientID}|${locationID}`);
    }
    const message: RequestAddConnection = {
      hostname: this.env.databaseURL,
      sourceID: 'SH',
      clientID: this.appState.clientID,
      locationID: locationID,
      connectionID: connectionID,
      merchantID: shop,
      merchantCode: token,
      updateOnly: false,
    };
    await this.functions.addConnection(message);

    this.alert.loadingMessage();

    // If it's not the primary connection, redirect to the connections page
    const updatedSettings = await this.settings.getRawSettings();
    if (updatedSettings.locations[0].connections[0].connectionID !== connectionID) {
      await this.showConnection(locationID, connectionID);
    }

    return token;
  }

  private async getShopifyToken(shop: string): Promise<string> {
    const doc = await this.afs.getDocument<{ tokens: ResourceShopifyToken[] }>('resources', 'shopifyTokens', false);
    const shopifyTokens = doc?.tokens ?? [];
    return shopifyTokens.find(token => token.shop === shop)?.token ?? '';
  }

  private async showConnection(locationID: string, connectionID: string): Promise<void> {
    // If it's not the primary connection, redirect to the connections page
    // Navigate to the home page first to enable back button on settings page
    await this.navigate.home({ replaceUrl: true });
    await this.router.navigate(['settings'],
      { queryParams: { locationID: locationID, connectionID: connectionID } });
  }

}
