import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  public mobile = false;
  public iOS = false;
  public iPhone = false;
  public iPad = false;
  public smallerThanLaptop = false;
  public smallerThanTablet = false;
  public splitInsights = false;
  public screenSize = 'M';
  public narrowMobile = false;

  constructor() {
    window.addEventListener('resize', () => this.getScreenSizes());
  }

  public initialize(): Promise<void> {
    return new Promise((resolve) => {
      const userAgent = window.navigator.userAgent;

      this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
      this.iOS = /iPad|iPhone|iPod/i.test(userAgent);
      this.iPhone = /iPhone/i.test(userAgent);
      this.iPad = /iPad/i.test(userAgent);
      this.getScreenSizes();

      resolve();
    });
  }

  private getScreenSizes(): void {
    const screenWidth = window.innerWidth;

    this.smallerThanLaptop = screenWidth < 1130;
    this.smallerThanTablet = screenWidth < 620;
    this.splitInsights = screenWidth > 799;
    this.narrowMobile = screenWidth < 400;
    this.screenSize = screenWidth < 725 || screenWidth < 400 ? 'M' : 'L';
  }
}
