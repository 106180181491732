import { Component, DestroyRef, OnInit, Renderer2 } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { IonicModule, MenuController } from '@ionic/angular';

import { filter, takeUntil } from 'rxjs';

import { CalendarIconComponent } from '@ion-components/calendar-icon/calendar-icon.component';

import { A2hsService } from '@services/a2hs.service';
import { ActiveService } from '@services/active.service';
import { AuthService } from '@services/auth.service';
import { ColorsService } from '@services/colors.service';
import { DarkService } from '@services/dark.service';
import { DeviceService } from '@services/device.service';
import { DtService } from '@services/dt.service';
import { EnvService } from '@services/env.service';
import { LsService } from '@services/ls.service';
import { SignoutService } from '@services/signout.service';
import { UpdateService } from '@pages/update/update.service';

import { AppState } from '@state/app.state';
import { UserState } from '@state/user.state';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
  imports: [
    NgOptimizedImage, IonicModule,
    CalendarIconComponent,
  ],
})
export class AppComponent implements OnInit {
  public version = '';

  constructor(
    public a2hs: A2hsService,
    public active: ActiveService,
    public dt: DtService,
    public device: DeviceService,
    public env: EnvService,
    public update: UpdateService,
    public userState: UserState,
    private appState: AppState,
    private auth: AuthService,
    private colors: ColorsService,
    private dark: DarkService,
    private destroyRef: DestroyRef,
    private ls: LsService,
    private menu: MenuController,
    private renderer: Renderer2,
    private router: Router,
    private signout: SignoutService,
  ) {
    this.appState.hideSplash$
      .pipe(this.signout.takeUntil(this.destroyRef))
      .subscribe(() => {
        const splash = this.renderer.selectRootElement('#splash');
        if (splash?.nativeElement) {
          const parent = splash.nativeElement.parentElement;
          this.renderer.removeChild(parent, splash);
        }
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.appState.hideSplash$),
        this.signout.takeUntil(this.destroyRef),
      )
      .subscribe(() => this.appState.hideSplash$.next());
  }

  async ngOnInit(): Promise<void> {
    void this.menu.enable(false);
    await this.checkBrowser();

    await this.auth.handleAuthParams();  // Handle Google/Facebook auth redirect
    this.appState.urlParams = window.location.search;
    this.update.initUpdates();
    this.version = this.env.version;
    this.a2hs.listenForInstallPrompt();
    this.dark.checkColorMode();
    await this.menu.swipeGesture(false);  // Disable menu swipe when menu is closed

    // Update theme color based on Preferences
    const metaThemeColor = this.renderer.selectRootElement('meta[name="theme-color"]');
    this.renderer.setAttribute(metaThemeColor, 'content', this.colors.colors['header-dark']);
  }

  private async checkBrowser(): Promise<void> {
    if (/MSIE |Trident\/|UC Browser/.test(window.navigator.userAgent)) {
      await this.router.navigate(['unsupported'], { state: { internalRequest: true } });
    }
  }

  public async updateMenuState(type: string): Promise<void> {
    this.appState.menuOpen = type === 'ionDidOpen';
    await this.menu.swipeGesture(type === 'ionDidOpen');  // Enable menu swipe only when menu is open
  }

  public async routerLink(route: string): Promise<void> {
    if (route.includes('/app/')) this.ls.set('lastTab', route.substring(5));
    await this.router.navigate([route]);
  }

  public async signOut(): Promise<void> {
    await this.menu.toggle();
    await this.auth.signOut();
  }
}
