import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { NavigateService } from '@services/navigate.service';

@Injectable({ providedIn: 'root' })
export class ExternalGuard {

  constructor(
    private navigate: NavigateService,
    private router: Router,
  ) { }

  public async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // Allow all internal requests for navigation
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state?.internalRequest || !!this.router.lastSuccessfulNavigation) return true;

    // Allow home page requests (with parameters)
    if (state.url.split('?')[0].split('#')[0] === '/') return true;

    try {
      await this.navigate.home();
    } catch (error) {
      console.error('Failed to navigate to home:', error);
    }

    return false;
  }
}
