/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';

import { getFunctions, httpsCallable } from 'firebase/functions';

import { firstValueFrom } from 'rxjs';

import { AlertService } from '@services/alert.service';
import { EnvService } from '@services/env.service';
import { ErrorService } from '@services/error.service';
import { SignoutService } from '@services/signout.service';
import { SourcesService } from '@services/sources.service';
import { UtilitiesService } from '@services/utilities.service';

import { ClientSettings } from '@shared/settings.interface';
import {
  RequestActivateConnection, RequestAddConnection, RequestCancelShopifyBilling, RequestCatalogMessage,
  RequestChangeDayEndHour, RequestChangeTimezone, RequestDeactivateConnection, RequestDeactivateLocation,
  RequestDeleteConnection, RequestDeleteLocation, RequestFinishConnection, RequestHTMLEmail, RequestRebuildTrends,
  RequestTemplateEmail, RequestUser,
}
  from '@shared/request.interface';
import { UserSettings } from '@shared/user.interface';

@Injectable({ providedIn: 'root' })
export class FunctionsService {

  private functions = getFunctions();

  constructor(
    private alert: AlertService,
    private error: ErrorService,
    private sources: SourcesService,
    private env: EnvService,
    private signout: SignoutService,
    private util: UtilitiesService,
  ) { }

  // CALLABLE FUNCTIONS

  public async getPasscode(uniqueID: string): Promise<string> {
    return await this.callStr('request-getPasscode', { uniqueID });
  }

  public async verifyPasscode(uniqueID: string, passcode: string): Promise<boolean> {
    return await this.callBool('request-verifyPasscode', { uniqueID, passcode });
  }

  public async setEmailVerified(uid: string): Promise<void> {
    try {
      await this.callAny('request-setEmailVerified', { uid });
    } catch (error) {
      console.error(`API functions.call: Failure for request-setEmailVerified; uid: ${uid}; ` +
        `${this.error.toStr(error)}`);
    }
  }

  public async addConnection(message: RequestAddConnection): Promise<void> {
    await this.call('request-addConnection2nd', message);
  }

  public finishConnection(message: RequestFinishConnection): void {
    void this.call('request-finishConnection', message);
  }

  public async changeTimezone(message: RequestChangeTimezone): Promise<void> {
    await this.util.sleep(3);  // Let autosave directive update Firestore first
    await this.call('request-changeTimezone', message);
  }

  public async changeDayEndHour(message: RequestChangeDayEndHour): Promise<void> {
    await this.util.sleep(3);  // Let autosave directive update Firestore first
    await this.call('request-changeDayEndHour', message);
  }

  public async sendEmail(message: RequestHTMLEmail): Promise<void> {
    await this.call('request-sendEmail', message);
  }

  public async sendTemplateEmail(message: RequestTemplateEmail): Promise<void> {
    await this.call('request-sendTemplateEmail2nd', message);
  }

  public async manageStripeSubscription(): Promise<void> {
    const data = await this.callAny('ext-firestore-stripe-payments-createPortalLink',
      { returnUrl: `${window.location.origin}/billing` });
    window.location.assign(data.url);
  }

  public async rebuildTrends(message: RequestRebuildTrends): Promise<void> {
    return await this.callAny('request-rebuildTrends2nd', message);
  }

  public async updateUser(uid: string, userSettings: UserSettings): Promise<void> {
    const request: RequestUser = { uid, userSettings };
    return await this.callAny('request-updateUser2nd', request);
  }

  public async deleteUser(uid: string): Promise<void> {
    return await this.callAny('request-deleteUser2nd', uid);
  }

  public async resyncCatalog(message: RequestCatalogMessage): Promise<void> {
    await this.call('request-resyncCatalog2nd', message);
  }

  public async activateConnection(message: RequestActivateConnection): Promise<void> {
    await this.call('request-activateConnection', message);
  }

  public async deactivateLocation(message: RequestDeactivateLocation): Promise<void> {
    await this.call('request-deactivateLocation', message);
  }

  public async deactivateConnection(message: RequestDeactivateConnection): Promise<void> {
    await this.call('request-deactivateConnection', message);
  }

  public async deleteLocation(message: RequestDeleteLocation): Promise<void> {
    await this.call('request-deleteLocation', message);
  }

  public async deleteConnection(message: RequestDeleteConnection): Promise<void> {
    await this.call('request-deleteConnection', message);
  }

  public async cancelShopifyBilling(message: RequestCancelShopifyBilling): Promise<void> {
    await this.call('request-cancelShopifyBilling', message);
  }

  public async updateInsights(clientID: string, locationID: string, settings: ClientSettings): Promise<void> {
    await this.call('request-updateInsights', { clientID, locationID, settings });
  }

  public async updateSpecialHours(clientID: string, locationID: string, date: string, openTime?: number,
    closeTime?: number): Promise<boolean | void> {
    return await this.callBool('request-updateSpecialHours', { clientID, locationID, date, openTime, closeTime });
  }

  // NEW TAB FUNCTIONS

  public async getToken(sourceID: string, clientID: string, locationID: string, connectionID: string): Promise<void> {
    const channelName = (this.sources.getSetting(sourceID, 'name') as string).toLowerCase().replace(' ', '');
    const url = `${this.env.databaseURL}/api/auth/${channelName}` +
      `?clientID=${clientID}&locationID=${locationID}&connectionID=${connectionID}`;
    const authWindow = window.open(url);
    if (authWindow) {
      await this.util.windowClosed(authWindow);
    }
  }

  public async getShopifyToken(): Promise<void> {
    await this.alert.confirm('Shopify App Authorization',
      `To authorize this app to access your Shopify data:\n\n` +
      `<b>Step 1:</b>\n` +
      `Log into the Shopify account you want to connect.\n\n` +
      `If you're already logged into another Shopify account, logout and then log into the correct Shopify account.\n\n` +
      `<b>Step 2:</b>\n` +
      `Tap the <b>Add app</b> button.\n\n` +
      `<b>Step 3:</b>\n` +
      `Then tap the <b>Install app</b> button.\n\n` +
      `<b>Step 4:</b>\n` +
      `Log back into the <b>Sprk™</b> app.`, 'info', 'Continue');
    this.alert.simpleMessage('Shopify App Authorization', 'Opening Shopify App Store...', 'info');

    window.location.assign('https://apps.shopify.com/manage-my-business');
  }

  public async addShopifyBilling(clientID: string, locationID: string, connectionID: string, merchantID: string,
    planPrice: number, trialDays: number, interval: string, currency: string): Promise<void> {

    await this.alert.confirm('Billing Authorization Via Shopify',
      `All subscription fees are paid through your Shopify account.\n\n` +
      `Please authorize the subscription billing on the following page.`, 'info', 'Continue');
    this.alert.simpleMessage('Billing Authorization Via Shopify', 'Opening Shopify Admin...', 'info');

    const url = `${this.env.databaseURL}/api/auth/shopify/billing` +
      `?shop=${merchantID}&clientID=${clientID}&locationID=${locationID}&connectionID=${connectionID}` +
      `&planPrice=${planPrice}&trialDays=${trialDays}&interval=${interval}&currency=${currency}`;

    location.assign(url);
  }

  // PRIVATE FUNCTIONS

  private call(name: string, message: any): Promise<unknown> {
    try {
      return Promise.race([
        httpsCallable(this.functions, name)(message).then((response: { data: any }): any => response.data),
        firstValueFrom(this.signout.signout$).then((): null => null),
      ]);
    } catch (error) {
      this.alert.loadingMessage();
      console.error(`functions.call: Failure for ${name}; message: ${JSON.stringify(message)}; ` +
        `${this.error.toStr(error)}`);
      return Promise.resolve(undefined);
    }
  }

  private callStr(name: string, message: any): Promise<string> {
    try {
      return Promise.race([
        httpsCallable(this.functions, name)(message).then(response => String(response.data)),
        firstValueFrom(this.signout.signout$).then(() => ''),
      ]);
    } catch (error) {
      this.alert.loadingMessage();
      console.error(`functions.callStr: Failure for ${name}; message: ${JSON.stringify(message)}; ` +
        `${this.error.toStr(error)}`);
      return Promise.resolve('');
    }
  }

  private callBool(name: string, message: any): Promise<boolean> {
    try {
      return Promise.race([
        httpsCallable(this.functions, name)(message).then(response => Boolean(response.data)),
        firstValueFrom(this.signout.signout$).then(() => false),
      ]);
    } catch (error) {
      this.alert.loadingMessage();
      console.error(`functions.callBool: Failure for ${name}; message: ${JSON.stringify(message)}; ` +
        `${this.error.toStr(error)}`);
      return Promise.resolve(false);
    }
  }

  private callAny(name: string, message: any): Promise<any> {
    try {
      return Promise.race([
        httpsCallable(this.functions, name)(message).then((response: { data: any }): any => response.data),
        firstValueFrom(this.signout.signout$).then((): null => null),
      ]);
    } catch (error) {
      this.alert.loadingMessage();
      console.error(`functions.callAny: Failure for ${name}; message: ${JSON.stringify(message)}; ` +
        `${this.error.toStr(error)}`);
      return Promise.resolve(undefined);
    }
  }
}
