import { Routes } from '@angular/router';

import { AuthGuard } from '@guards/auth.guard';
import { ExternalGuard } from '@guards/external.guard';

import { TabsPage } from '@navigation/tabs.page';

let initialTab = window.localStorage.getItem('initialTab') ?? 'last';
if (initialTab === 'today') initialTab = 'dashboard';

let lastTab = window.localStorage.getItem('lastTab') ?? 'dashboard';
if (lastTab === 'today') lastTab = 'dashboard';

const appRoute = initialTab === 'last' ? `app/${lastTab}` : `app/${initialTab}`;

export const routes: Routes = [
  { path: '', redirectTo: appRoute, pathMatch: 'full' },
  {
    path: 'app',
    component: TabsPage,
    children: [
      {
        path: 'insights',
        loadComponent: () => import('@pages/insights/insights.page').then(m => m.InsightsPage),
        canActivate: [AuthGuard],
      },
      {
        path: 'dashboard',
        loadComponent: () => import('@pages/today/today.page').then(m => m.TodayPage),
        canActivate: [AuthGuard],
      },
      {
        path: 'trends',
        loadComponent: () => import('@pages/trends/trends.page').then(m => m.TrendsPage),
        canActivate: [AuthGuard],
      },
      { path: '**', redirectTo: '/error' },
    ],
  },
  {
    path: 'billing',
    loadComponent: () => import('@pages/billing/billing.page').then(m => m.BillingPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'catalog',
    loadComponent: () => import('@pages/catalog/catalog.page').then(m => m.CatalogPage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'configure',
    loadComponent: () => import('@pages/configure/configure.page').then(m => m.ConfigurePage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'demo',
    loadComponent: () => import('@pages/demo/demo.page').then(m => m.DemoPage),
  },
  {
    path: 'error',
    loadComponent: () => import('@pages/error/error.page').then(m => m.ErrorPage),
  },
  {
    path: 'google',
    loadComponent: () => import('@pages/google/google.page').then(m => m.GooglePage),
    canActivate: [AuthGuard],
  },
  {
    path: 'help',
    loadComponent: () => import('@pages/help/help.page').then(m => m.HelpPage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'inactive',
    loadComponent: () => import('@pages/inactive/inactive.page').then(m => m.InactivePage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'license',
    loadComponent: () => import('@pages/license/license.page').then(m => m.LicensePage),
  },
  {
    path: 'locations',
    loadComponent: () => import('@pages/locations/locations.page').then(m => m.LocationsPage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'pos-connect',
    loadComponent: () => import('@pages/pos-connect/pos-connect.page').then(m => m.PosConnectPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'preferences',
    loadComponent: () => import('@pages/preferences/preferences.page').then(m => m.PreferencesPage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'profit',
    loadComponent: () => import('@pages/profit/profit.page').then(m => m.ProfitPage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'reset-password',
    loadComponent: () => import('@pages/reset-password/reset-password.page').then(m => m.ResetPasswordPage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'releases',
    loadComponent: () => import('@pages/releases/releases.page').then(m => m.ReleasesPage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'retrieving',
    loadComponent: () => import('@pages/retrieving/retrieving.page').then(m => m.RetrievingPage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'settings',
    loadComponent: () => import('@pages/settings/settings.page').then(m => m.SettingsPage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'signin',
    loadComponent: () => import('@pages/signin/signin.page').then(m => m.SigninPage),
  },
  {
    path: 'signout',
    loadComponent: () => import('@pages/signin/signin.page').then(m => m.SigninPage),
  },
  {
    path: 'unsupported',
    loadComponent: () => import('@pages/unsupported/unsupported.page').then(m => m.UnsupportedPage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'update',
    loadComponent: () => import('@pages/update/update.page').then(m => m.UpdatePage),
  },
  {
    path: 'users',
    loadComponent: () => import('@pages/users/users.page').then(m => m.UsersPage),
    canActivate: [ExternalGuard],
  },
  {
    path: 'verify-email',
    loadComponent: () => import('@pages/verify-email/verify-email.page').then(m => m.VerifyEmailPage),
    canActivate: [ExternalGuard],
  },
  { path: '**', redirectTo: 'error' },
];
