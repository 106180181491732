export interface SourceSettings {
  id: string;
  channelIDs: string[];
  name: string;
  fullName: string;
  licensed: boolean;
  active: boolean;
  type: string[];
  source: string;
  fieldsRequiredToConnect: string[],
  icon: string;
  merchantIDLabelLong?: string;
  merchantIDLabelShort?: string;
  tokenLabel?: string;
  singleInstance?: boolean;
  beta?: boolean;
}

export const sourceSettings: SourceSettings[] = [
  {
    id: 'CL',
    channelIDs: ['CL', 'DD', 'GH', 'UE'],
    name: 'Clover',
    fullName: 'Clover',
    licensed: false,
    active: false,
    type: ['pos'],
    source: 'api',
    fieldsRequiredToConnect: [],
    icon: 'assets/icons/clover.png',
    merchantIDLabelLong: 'Clover Merchant ID',
    merchantIDLabelShort: 'Merchant ID',
  },
  {
    id: 'GO',
    channelIDs: [],
    name: 'Google',
    fullName: 'Google Business Profile',
    licensed: false,
    active: false,
    type: ['profile'],
    source: 'api',
    fieldsRequiredToConnect: [],
    icon: 'assets/icons/google.png',
    merchantIDLabelLong: '',
    merchantIDLabelShort: '',
    tokenLabel: '',
    singleInstance: true,
  },
  {
    id: 'SH',
    channelIDs: ['SHP', 'SHE'],
    name: 'Shopify',
    fullName: 'Shopify',
    licensed: false,
    active: false,
    type: ['pos', 'ecommerce'],
    source: 'api',
    fieldsRequiredToConnect: [],
    icon: 'assets/icons/shopify.png',
    merchantIDLabelLong: 'Shopify Shop Name',
    merchantIDLabelShort: 'Shop Name',
    beta: true,
  },
  {
    id: 'SQ',
    channelIDs: ['SQP', 'SQE', 'DD', 'UE'],
    name: 'Square',
    fullName: 'Square',
    licensed: false,
    active: false,
    type: ['pos', 'ecommerce'],
    source: 'api',
    fieldsRequiredToConnect: [],
    icon: 'assets/icons/square.png',
    merchantIDLabelLong: 'Square Merchant ID',
    merchantIDLabelShort: 'Merchant ID',
  },
];
