import { Injectable } from '@angular/core';

import { Timestamp } from 'firebase/firestore';

import { copy } from 'copy-anything';
import { dequal } from 'dequal';

import { DtService } from '@services/dt.service';

@Injectable({ providedIn: 'root' })
export class UtilitiesService {
  private epsilon = Math.pow(10, -14);

  constructor(
    private dt: DtService,
  ) { }

  public sleep(seconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
  }

  public windowClosed(windowName: Window): Promise<void> {
    return new Promise(resolve => {
      const timer = setInterval(() => {
        if (!windowName || windowName.closed) {
          clearInterval(timer);
          resolve();
        }
      }, 500);
    });
  }

  public shallowClone<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
  }

  public deepClone<T>(value: T): T {
    return copy<T>(value);
  }

  public isEqualArray(oldArray: string[], newArray: string[]): boolean {
    if (!oldArray || !newArray || oldArray.length !== newArray.length) return false;

    // Sort both arrays to ensure order doesn't matter
    const sortedOldArray = oldArray.slice().sort();
    const sortedNewArray = newArray.slice().sort();

    // Compare the sorted arrays element by element
    for (let i = 0; i < sortedOldArray.length; i++) {
      if (sortedOldArray[i] !== sortedNewArray[i]) return false;
    }

    return true;
  }

  public isEqualShallow(oldObject: any, newObject: any): boolean {  // eslint-disable-line
    if (!oldObject || !newObject) return false;

    const keys1 = Object.keys(oldObject || {});
    const keys2 = Object.keys(newObject || {});

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (oldObject[key] !== newObject[key]) return false;
    }

    return true;
  }

  public isDiffShallow(oldObject: any, newObject: any): boolean {  // eslint-disable-line
    return !this.isEqualShallow(oldObject, newObject);
  }

  public isEqualDeep(oldObject: any, newObject: any, fieldsToIgnore: string[] = []): boolean {  // eslint-disable-line
    if (!fieldsToIgnore.length) return dequal(oldObject, newObject);

    const oldObjectClean = this.cleanObject(oldObject, fieldsToIgnore);
    const newObjectClean = this.cleanObject(newObject, fieldsToIgnore);
    return dequal(oldObjectClean, newObjectClean);
  }

  public isDiffDeep(oldObject: any, newObject: any, fieldsToIgnore: string[] = []): boolean {  // eslint-disable-line
    return !this.isEqualDeep(oldObject, newObject, fieldsToIgnore);
  }

  private cleanObject(obj: any, fieldsToRemove: string[]): any {
    if (Array.isArray(obj)) {
      return obj.map(item => this.cleanObject(item, fieldsToRemove));
    } else if (obj && typeof obj === 'object') {
      return Object.keys(obj).reduce((cleanedObj, key) => {
        if (!fieldsToRemove.includes(key)) {
          cleanedObj[key] = obj[key];
        }
        return cleanedObj;
      }, {} as Record<string, any>);
    }
    return obj;
  }

  public timeOfDay(): string {
    const time = this.dt.getCurrentTime();
    return time < 12 ? 'morning' : time < 17 ? 'afternoon' : 'evening';
  }

  public applySentenceCase(text: string): string {
    // Capitalize first letter of string and first letter of each subsequent sentence
    if (!text) return '';
    text = `${text[0].toUpperCase()}${text.substring(1)}`;
    return text.replace(/.+?[.?!](\s|$)/g, str => `${str[0].toUpperCase()}${str.substring(1)}`);
  }

  public ordinalSuffix(i: number): string {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) return String(i) + 'st';
    if (j === 2 && k !== 12) return String(i) + 'nd';
    if (j === 3 && k !== 13) return String(i) + 'rd';
    return String(i) + 'th';
  }

  public cleanseID(id: string): string {
    return !id ? '' : id.replace(/[^\w\-_|]/g, '');
  }

  public removeSourceID(id: string): string {
    return id && id.length > 3 && id.substring(2, 3) === '-' ? id.substring(3) : id;
  }

  public escapeCSVField(field: string): string {
    return !field ? '' : field.includes('"') ? field.replace(/"/g, '""') : field;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public parseTimestampToDate(doc: any): any {
    if (!doc) return doc;

    // Check if the value is a Firestore Timestamp and convert it to Date
    if (doc instanceof Timestamp) return doc.toDate();

    // If the value is an array, recursively process each element
    if (Array.isArray(doc)) return doc.map(item => this.parseTimestampToDate(item));

    // If the value is an object, recursively process each key-value pair
    if (typeof doc === 'object' && doc !== null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newDoc: { [key: string]: any } = {};
      Object.keys(doc).forEach(key => newDoc[key] = this.parseTimestampToDate(doc[key]));
      return newDoc;
    }

    // If it's not a Firestore Timestamp, array, or object, return the value as is
    return doc;
  }

  public getStartEndHour(start: number, end: number, hoursWithSales: number[],
    isTypical: boolean): { startHour: number, endHour: number } {

    // Find start/end hours given sales by hour data
    let startHour = start;
    let endHour = end;
    const maxDays = Math.max(...hoursWithSales);

    if (maxDays > 0) {
      for (let i = start; i <= end; i++) {
        if (isTypical && hoursWithSales[i] / maxDays < 0.4) {
          startHour = i + 1;
        } else {
          break;
        }
      }

      for (let i = end; i >= startHour; i--) {
        if (isTypical && hoursWithSales[i] / maxDays < 0.4) {
          endHour = i - 1;
        } else {
          break;
        }
      }
    }

    return { startHour, endHour };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public findUndefinedProperties(obj: any, prefix: string = ''): string[] {
    let undefinedProps: string[] = [];

    // Iterate over each property in the object
    for (const key of Object.keys(obj)) {
      const fullPath = prefix ? `${prefix}.${key}` : key;

      // If the property value is undefined, add its path to the list
      if (obj[key] === undefined) {
        undefinedProps.push(fullPath);
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        // If the property is an object, recursively search for undefined properties
        undefinedProps = undefinedProps.concat(this.findUndefinedProperties(obj[key], fullPath));
      }
    }

    return undefinedProps;
  }

  public round(number: number, decimals = 0): number {
    const factor = Math.pow(10, decimals);
    return Math.round((number + this.epsilon) * factor) / factor;
  }

  public getCountry(postalCode: string): string {
    return isNaN(Number(postalCode)) ? 'CA' : 'US';
  }

  public getStateProvince(postalCode: string): string {
    if (!postalCode) return '';

    if (isNaN(Number(postalCode))) {
      const firstChar = postalCode[0];
      if (firstChar === 'T') return 'AB';
      if (firstChar === 'V') return 'BC';
      if (firstChar === 'R') return 'MB';
      if (firstChar === 'E') return 'NB';
      if (firstChar === 'A') return 'NL';
      if (firstChar === 'B') return 'NS';
      if (firstChar === 'X') return 'NT';
      if (['L', 'M', 'N', 'P'].includes(firstChar)) return 'ON';
      if (firstChar === 'C') return 'PE';
      if (['G', 'H', 'J'].includes(firstChar)) return 'QC';
      if (firstChar === 'S') return 'SK';
      if (firstChar === 'Y') return 'YT';
      return '';
    } else {
      const value = Number(postalCode);
      if (value >= 35000 && value <= 36999) return 'AL';
      if (value >= 99500 && value <= 99999) return 'AK';
      if (value >= 85000 && value <= 86999) return 'AZ';
      if (value >= 71600 && value <= 72999) return 'AR';
      if (value >= 90000 && value <= 96699) return 'CA';
      if (value >= 80000 && value <= 81999) return 'CO';
      if (value >= 6000 && value <= 6999) return 'CT';
      if (value >= 19700 && value <= 19999) return 'DE';
      if (value >= 32000 && value <= 34999) return 'FL';
      if (value >= 30000 && value <= 31999) return 'GA';
      if (value >= 96700 && value <= 96999) return 'HI';
      if (value >= 83200 && value <= 83999) return 'ID';
      if (value >= 60000 && value <= 62999) return 'IL';
      if (value >= 46000 && value <= 47999) return 'IN';
      if (value >= 50000 && value <= 52999) return 'IA';
      if (value >= 66000 && value <= 67999) return 'KS';
      if (value >= 40000 && value <= 42999) return 'KY';
      if (value >= 70000 && value <= 71599) return 'LA';
      if (value >= 3900 && value <= 4999) return 'ME';
      if (value >= 20600 && value <= 21999) return 'MD';
      if (value >= 1000 && value <= 2799) return 'MA';
      if (value >= 48000 && value <= 49999) return 'MI';
      if (value >= 55000 && value <= 56999) return 'MN';
      if (value >= 38600 && value <= 39999) return 'MS';
      if (value >= 63000 && value <= 65999) return 'MO';
      if (value >= 59000 && value <= 59999) return 'MT';
      if (value >= 27000 && value <= 28999) return 'NC';
      if (value >= 58000 && value <= 58999) return 'ND';
      if (value >= 68000 && value <= 69999) return 'NE';
      if (value >= 88900 && value <= 89999) return 'NV';
      if (value >= 3000 && value <= 3899) return 'NH';
      if (value >= 7000 && value <= 8999) return 'NJ';
      if (value >= 87000 && value <= 88499) return 'NM';
      if (value >= 10000 && value <= 14999) return 'NY';
      if (value >= 43000 && value <= 45999) return 'OH';
      if (value >= 73000 && value <= 74999) return 'OK';
      if (value >= 97000 && value <= 97999) return 'OR';
      if (value >= 15000 && value <= 19699) return 'PA';
      if (value >= 300 && value <= 999) return 'PR';
      if (value >= 2800 && value <= 2999) return 'RI';
      if (value >= 29000 && value <= 29999) return 'SC';
      if (value >= 57000 && value <= 57999) return 'SD';
      if (value >= 37000 && value <= 38599) return 'TN';
      if ((value >= 75000 && value <= 79999) || (value >= 88500 && value <= 88599)) return 'TX';
      if (value >= 84000 && value <= 84999) return 'UT';
      if (value >= 5000 && value <= 5999) return 'VT';
      if (value >= 22000 && value <= 24699) return 'VA';
      if (value >= 20000 && value <= 20599) return 'DC';
      if (value >= 98000 && value <= 99499) return 'WA';
      if (value >= 24700 && value <= 26999) return 'WV';
      if (value >= 53000 && value <= 54999) return 'WI';
      if (value >= 82000 && value <= 83199) return 'WY';
      return '';
    }
  }

}
