import { DestroyRef, Injectable } from '@angular/core';

import { AfsService } from '@services/afs.service';
import { ChannelsService } from '@services/channels.service';
import { Color, ColorsService } from '@services/colors.service';
import { SignoutService } from '@services/signout.service';

import { ChannelSettings } from '@shared/channels.interface';
import { ClientSettings } from '@shared/settings.interface';
import { SourceSettings, sourceSettings } from '@shared/sources.interface';

import { AppState } from '@state/app.state';
import { UserState } from '@state/user.state';

@Injectable({ providedIn: 'root' })
export class SourcesService {
  public settings: SourceSettings[] = [];
  public licensed: SourceSettings[] = [];

  public channelIDLookup: { [key: string]: string } = {};

  public unusedColors: Color[] = [];

  constructor(
    private afs: AfsService,
    private appState: AppState,
    private channels: ChannelsService,
    private colors: ColorsService,
    private destroyRef: DestroyRef,
    private signout: SignoutService,
    private userState: UserState,
  ) {
    this.settings = sourceSettings;

    this.userState.isDemo$
      .pipe(this.signout.takeUntil(this.destroyRef))
      .subscribe({
        next: isDemo => {
          if (isDemo) {
            const demoPOS = this.userState.getPref('demoPOS', 'CL') as string;
            if (demoPOS !== 'CL') {
              const newSource = this.settings.find(source => source.id === demoPOS);
              if (newSource) {
                const channelIndex = newSource?.type.findIndex(type => type === 'pos');
                if (channelIndex !== -1) {
                  const channelID = newSource.channelIDs[channelIndex];

                  this.settings[0].name = newSource.name;
                  this.settings[0].icon = newSource.icon;
                  this.settings[0].merchantIDLabelLong = newSource.merchantIDLabelLong;
                  this.settings[0].merchantIDLabelShort = newSource.merchantIDLabelShort;

                  this.channels.demoOverride(channelID);
                }
              }
            }
          }
        },
      });
  }

  public async update(clientSettings: ClientSettings): Promise<void> {
    // Assign colors to defined channels
    await this.assignChannelColors(clientSettings);

    for (const source of this.settings) {
      const sourceID = source.id;
      this.setLicensed(sourceID, !source.beta || this.userState.betaUser);

      // Set source active if any connection exists for that source (including inactive connections)
      let sourceActive = false;
      if (clientSettings) {
        // Remove connections that require direct billing
        if (['CL', 'ST', 'AS'].includes(clientSettings.license.billingAgent) && !this.userState.betaUser) {
          this.setLicensed('SH', false);
        }
        if (['SH', 'ST', 'AS'].includes(clientSettings.license.billingAgent)) {
          this.setLicensed('CL', false);
        }

        const location = clientSettings.locations.find(location => location.locationID === this.appState.locationID);
        if (location?.connections) {
          for (const connection of location.connections) {
            if (connection.sourceID === sourceID && connection.active && !sourceActive) {
              sourceActive = true;
            }
          }
        }
      }
      this.setActive(sourceID, sourceActive);
    }

    this.channels.update(this.settings, clientSettings);
    this.licensed = this.settings.filter(channel => channel.licensed);

    this.channelIDLookup = {};
    for (const source of this.settings) {
      if (source.type.includes('pos') && source.active) {
        for (const channelID of source.channelIDs) {
          this.channelIDLookup[channelID] = source.id;
        }
      }
    }
  }

  public async assignChannelColors(clientSettings: ClientSettings, firestoreUpdate = true): Promise<void> {
    let updateNeeded = false;

    // Reset inUse flags before assigning colors
    for (const channelColor of this.colors.channelColors) {
      channelColor.inUse = false;
    }

    for (const location of clientSettings.locations) {
      for (const connection of (location?.connections ?? [])) {
        for (const channelID of this.getChannelIDsFromSourceID(connection.sourceID)) {
          const channelMatch = clientSettings.channels.find(channel => channel.channelID === channelID);
          if (channelMatch) {
            const colorMatch = this.colors.channelColors.find(channelColor =>
              channelColor.color === channelMatch.color);
            if (colorMatch) colorMatch.inUse = true;
          } else {
            const channel = {
              channelID: channelID,
              color: this.channels.setColor(channelID),
            };
            clientSettings.channels.push(channel);
            updateNeeded = true;
          }
        }
      }
    }

    if (updateNeeded && firestoreUpdate) {
      await this.afs.setDocument('clients', this.appState.clientID, { channels: clientSettings.channels },
        { merge: true });
    }
  }

  private getSourceIndex(sourceID: string): number {
    return this.settings?.findIndex(source => source.id === sourceID);
  }

  public getSetting(sourceID: string, setting: keyof SourceSettings): string | string[] | boolean | undefined {
    if (this.settings && sourceID && setting) {
      const sourceIndex = this.getSourceIndex(sourceID);
      return sourceIndex === -1 ? undefined : this.settings[sourceIndex][setting];
    }
    return '';
  }

  public getSettingStringArray(sourceID: string, setting: keyof SourceSettings): string[] {
    if (this.settings && sourceID && setting) {
      const sourceIndex = this.getSourceIndex(sourceID);
      return sourceIndex === -1 ? [] : this.settings[sourceIndex][setting] as string[];
    }
    return [];
  }

  private setLicensed(sourceID: string, value: boolean): void {
    const index = this.getSourceIndex(sourceID);
    if (index !== -1) {
      this.settings[index].licensed = value;
    }
  }

  public setActive(sourceID: string, value: boolean): void {
    const index = this.getSourceIndex(sourceID);
    if (index !== -1) {
      this.settings[index].active = value;
    }
  }

  public posSources(): SourceSettings[] {
    return this.settings.filter(source => source.type.includes('pos') && (!source.beta || this.userState.betaUser));
  }

  public getChannels(sourceID: string): ChannelSettings[] {
    return this.channels.channelsForSourceID(this.getSetting(sourceID, 'channelIDs') as string[]);
  }

  public getChannelIDsFromSourceID(sourceID: string): string[] {
    return this.channels.channelsForSourceID(this.getSetting(sourceID, 'channelIDs') as string[])
      .map(channel => channel.id) || [];
  }

  public getSourceIDsFromChannelIDs(channelIDs: string[]): string[] {
    const sourceIDs = new Set<string>();
    for (const setting of this.settings) {
      for (const channelID of channelIDs) {
        if (setting.channelIDs.includes(channelID)) {
          sourceIDs.add(setting.id);
        }
      }
    }
    return [...sourceIDs];
  }

  public setColor(sourceID: string, channelIndex: number, color: string, prevColor?: string): void {
    if (!sourceID || !color) return;

    const channelIDs = (this.getSetting(sourceID, 'channelIDs') as string[]) ?? [];
    const channelID = channelIndex < channelIDs.length ? channelIDs[channelIndex] : channelIDs[0];

    this.channels.setColor(channelID, color);
    if (prevColor && color !== prevColor) {
      this.channels.releaseColor(channelID, prevColor);
    }
  }

  public updateUnusedColors(selectedColor: string): void {
    this.unusedColors = this.colors.channelColors.filter(color => color.color === selectedColor || !color.inUse);
  }

  public getSelectedChannelNames(sourceID: string, channelIndex: number): { selected: string, other: string, short: string } {
    const channelIDs = (this.getSetting(sourceID, 'channelIDs') as string[]) ?? [];
    const channelID = channelIDs[channelIndex];

    const otherChannelIndex = Math.abs(channelIndex - 1);  // Switch 0 to 1 and 1 to 0
    const otherChannelID = channelIDs[otherChannelIndex];

    return {
      selected: this.channels.getSetting(channelID, 'fullName') as string,
      other: this.channels.getSetting(otherChannelID, 'fullName') as string,
      short: this.channels.getSetting(otherChannelID, 'shortName') as string,
    };
  }
}
