<ion-tabs (ionTabsWillChange)="tabChange($event.tab)">
  @if (device.smallerThanLaptop) {
    <ion-tab-bar slot="bottom" [style.background]="colors.colors['header']">
      <ion-tab-button
        tab="insights"
        [style.background]="tabSelected === 'insights' ? colors.colors['header-dark'] : colors.colors['header']"
      >
        <img class="icon" ngSrc="assets/icons/tabs/insights.webp" width="85" height="85" alt="Insights icon" />
        <ion-label [style]="insightsFontStyle">Insights</ion-label>
      </ion-tab-button>
      <ion-tab-button
        tab="dashboard"
        [style.background]="tabSelected === 'dashboard' ? colors.colors['header-dark'] : colors.colors['header']"
      >
        <app-calendar-icon></app-calendar-icon>
        <ion-label [style]="todayFontStyle">Dashboard</ion-label>
      </ion-tab-button>
      <ion-tab-button
        tab="trends"
        [style.background]="tabSelected === 'trends' ? colors.colors['header-dark'] : colors.colors['header']"
      >
        <img class="icon" ngSrc="assets/icons/tabs/trends.webp" width="69" height="69" alt="Trends icon" />
        <ion-label [style]="trendsFontStyle">Trends</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  }
</ion-tabs>
