import { Injectable } from '@angular/core';

import {
  addDoc, clearIndexedDbPersistence, collection, CollectionReference, deleteDoc, doc, DocumentData, DocumentReference,
  Firestore, getDoc, getDocs, getDocsFromCache, getDocsFromServer, initializeFirestore, onSnapshot, persistentLocalCache,
  persistentMultipleTabManager, Query, query, QuerySnapshot, setDoc, SetOptions, where,
} from 'firebase/firestore';

import { combineLatest, Observable } from 'rxjs';

import { ActiveService } from '@services/active.service';
import { DtService } from '@services/dt.service';
import { ErrorService } from '@services/error.service';
import { firebaseApp } from '@services/firebase.service';
import { LsService } from '@services/ls.service';

import { PendingUser, UserSettings } from '@shared/user.interface';
import { TrendsByHourPacked } from '@shared/trends.interface';

interface DocWithId {
  id: string;
}

@Injectable({ providedIn: 'root' })
export class AfsService {

  private firestore: Firestore;

  constructor(
    private active: ActiveService,
    private dt: DtService,
    private error: ErrorService,
    private ls: LsService,
  ) {
    try {
      this.firestore = initializeFirestore(firebaseApp, {
        localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
      });
    } catch (error) {
      console.error('Firestore initialization error', error);
    }
  }

  public async initialize(): Promise<void> {
    // Force Firestore cache to be removed based on this version's counter (currently 22)
    const clearCacheID = 22;
    if (Number(this.ls.get('clearCacheID', '0')) < clearCacheID) {
      this.ls.set('clearCacheID', String(clearCacheID));
      await clearIndexedDbPersistence(this.firestore);
    }
  }

  // *** CRUD FUNCTIONS ***

  public async getDocument<T>(collection: string, document: string, throwError = true): Promise<T | undefined> {
    try {
      const docSnap = await getDoc(doc(this.firestore, collection, document));
      if (docSnap.exists()) {
        return docSnap.data() as T;
      } else if (throwError) {
        throw new Error(`Document not found: ${collection}/${document}`);
      } else {
        return undefined;
      }
    } catch (error) {
      if (throwError) {
        throw new Error(`afs.getDocument: ${collection}/${document} failed with ${this.error.toStr(error)}`);
      } else {
        return undefined;
      }
    }
  }

  public getDocumentsWithSubcollection<T extends DocWithId>(collectionPath: string, subCollectionPath: string):
    Observable<T[]> {

    return new Observable<T[]>((observer) => {
      const colRef = collection(this.firestore, collectionPath);

      const unsubscribeMain = onSnapshot(colRef, (snapshot: QuerySnapshot<DocumentData>) => {
        const mainDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as T));

        // Create observables for each subcollection and merge them
        const subCollectionObservables = mainDocs.map(mainDoc => {
          const subColRef = collection(this.firestore, `${collectionPath}/${mainDoc.id}/${subCollectionPath}`);

          return new Observable<T>((subObserver) => {
            const unsubscribeSub = onSnapshot(subColRef, (subSnapshot) => {
              const subDocs = subSnapshot.docs.map(subDoc => ({ id: subDoc.id, ...subDoc.data() }));
              subObserver.next({ ...mainDoc, [subCollectionPath]: subDocs });
            });

            return () => unsubscribeSub();
          });
        });

        // Combine all observables from the subcollections
        combineLatest(subCollectionObservables).subscribe({
          next: (documentsWithSubcollections) => observer.next(documentsWithSubcollections),
          error: (error) => observer.error(new Error(`afs.getDocumentsWithSubcollection: ` +
            `${collectionPath}/${subCollectionPath}, ${this.error.toStr(error)}`)),
        });
      });

      // Cleanup for the main collection observer
      return () => unsubscribeMain();
    });
  }

  public async setDocument<T>(collection: string, documentId: string, data: T, options?: SetOptions): Promise<void> {
    const docRef = doc(this.firestore, `${collection}/${documentId}`);

    try {
      await setDoc(docRef, data as unknown as object, options ?? {});
    } catch (error) {
      if (collection === 'users') {
        this.error.message('', '', 'afs.setDocument', `Error updating users: ${documentId}, ${JSON.stringify(data)}`);
      }
      if (collection === 'clients') {
        this.error.message('', '', 'afs.setDocument', `Error updating clients: ${documentId}, ${JSON.stringify(data)}`);
      }
      throw new Error(`afs.setDocument: ${collection}/${documentId} failed, ${this.error.toStr(error)}`);
    }
  }


  public async deleteDocument(collection: string, documentId: string, throwError = true): Promise<void> {
    try {
      const docRef = doc(this.firestore, `${collection}/${documentId}`);
      await deleteDoc(docRef);
    } catch (error) {
      if (throwError) {
        throw new Error(`deleteDocument: ${collection}/${documentId} failed, ${this.error.toStr(error)}`);
      }
    }
  }

  public async deleteExtraDocs(clientID: string, locationID: string, collectionPath: string, newCount: number):
    Promise<void> {

    const q = query(collection(this.firestore, collectionPath),
      where('_clientID', '==', clientID),
      where('_locationID', '==', locationID),
      where('_index', '>', newCount),
    );

    try {
      const snapshot = await getDocs(q);
      const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);
    } catch (error) {
      console.error(`afs.deleteExtraDocs, ${this.error.toStr(error)}`);
    }
  }

  // OBSERVABLE FUNCTIONS

  public getValueChanges<T>(collection: string, document: string): Observable<T | undefined> {
    return new Observable<T | undefined>((observer) => {
      const docRef = doc(this.firestore, collection, document);
      const unsubscribe = onSnapshot(docRef, (snapshot) => {
        if (snapshot.exists()) {
          observer.next(snapshot.data() as T);
        } else {
          observer.next();
        }
      }, (error) => {
        observer.error(new Error(`afs.getValueChanges for ${collection}/${document}: ${this.error.toStr(error)}`));
      });

      return { unsubscribe };
    });
  }

  public getValueChangesFromLocation<T>(collectionName: string, clientID: string, locationID: string):
    Observable<T[] | undefined> {

    return new Observable<T[] | undefined>((observer) => {
      const colRef = collection(this.firestore, collectionName);
      const q = query(colRef,
        where('_clientID', '==', clientID),
        where('_locationID', '==', locationID));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data: T[] = snapshot.docs.map(doc => doc.data() as T);
        observer.next(data);
      }, (error) => {
        observer.error(new Error(`afs.getValueChangesFromLocation for ` +
          `${collectionName}/${clientID}|${locationID}: ${this.error.toStr(error)}`));
      });

      return { unsubscribe };
    });
  }

  public getValueChangesFromLocations<T>(collectionName: string, clientID: string, locationIDs: string[]):
    Observable<T[] | undefined> {

    return new Observable<T[] | undefined>((observer) => {
      const colRef = collection(this.firestore, collectionName);
      const q = query(colRef,
        where('_clientID', '==', clientID),
        where('_locationID', 'in', locationIDs));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data: T[] = snapshot.docs.map(doc => doc.data() as T);
        observer.next(data);
      }, (error) => {
        observer.error(new Error(`afs.getValueChangesFromLocations for ` +
          `${collectionName}/${clientID}|${locationIDs}: ${this.error.toStr(error)}`));
      });

      return { unsubscribe };
    });
  }

  // TODAY FUNCTIONS

  public async getToday<T>(clientID: string, locationIDs: string[], dates: string[]):
    Promise<QuerySnapshot<T> | undefined> {

    const todayQuery = this.getTodayQuery<T>(clientID, locationIDs, dates);

    // Use getDocsFromServer or getDocsFromCache based on connectivity
    return this.active.isConnected ?
      await getDocsFromServer(todayQuery) :
      await getDocsFromCache(todayQuery);
  }

  private getTodayQuery<T>(clientID: string, locationIDs: string[], dates: string[]): Query<T> {
    // Firestore has a limit of 30 combinations of locations/dates
    const { firstDate, lastDate } = dates.reduce((acc, date) => {
      if (date < acc.firstDate) acc.firstDate = date;
      if (date > acc.lastDate) acc.lastDate = date;
      return acc;
    }, { firstDate: dates[0], lastDate: dates[0] });

    const colRef = collection(this.firestore, 'today').withConverter<T>({
      toFirestore: (data) => data as DocumentData,
      fromFirestore: (snap) => snap.data() as T,
    });

    if (locationIDs.length === 1) {
      if (dates.length > 30) {
        return query(colRef,
          where('_clientID', '==', clientID),
          where('_locationID', '==', locationIDs[0]),
          where('_date', '>=', firstDate),
          where('_date', '<=', lastDate),
        );
      } else {
        return query(colRef,
          where('_clientID', '==', clientID),
          where('_locationID', '==', locationIDs[0]),
          where('_date', 'in', dates),
        );
      }
    }

    if (locationIDs.length > 1) {
      if (locationIDs.length * dates.length > 30 && locationIDs.length > 30) {
        return query(colRef,
          where('_clientID', '==', clientID),
          where('_date', '>=', firstDate),
          where('_date', '<=', lastDate),
        );
      } else if (locationIDs.length * dates.length > 30) {
        return query(colRef,
          where('_clientID', '==', clientID),
          where('_locationID', 'in', locationIDs),
          where('_date', '>=', firstDate),
          where('_date', '<=', lastDate),
        );
      } else {
        return query(colRef,
          where('_clientID', '==', clientID),
          where('_locationID', 'in', locationIDs),
          where('_date', 'in', dates),
        );
      }
    }
  }

  public getTodayChanges<T>(clientID: string, locationIDs: string[], dates: string[]): Observable<T[] | undefined> {
    return new Observable<T[]>((observer) => {
      const q = this.getTodayChangesQuery(clientID, locationIDs, dates);

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const changes: T[] = querySnapshot.docs.map((doc) => doc.data() as T);
        observer.next(changes);
      }, (error) => {
        observer.error(new Error(`afs.getTodayChanges: ${this.error.toStr(error)}`));
      });

      return () => unsubscribe();
    });
  }

  private getTodayChangesQuery(clientID: string, locationIDs: string[], dates: string[]): Query<DocumentData> {
    const colRef = collection(this.firestore, 'today');
    const lastChecked = this.dt.now();

    if (locationIDs.length * dates.length > 30 && locationIDs.length > 30) {
      return query(colRef,
        where('_clientID', '==', clientID),
        where('_lastUpdated', '>', lastChecked),
      );
    } else if (locationIDs.length * dates.length > 30) {
      return query(colRef,
        where('_clientID', '==', clientID),
        where('_locationID', 'in', locationIDs),
        where('_lastUpdated', '>', lastChecked),
      );
    } else {
      return query(colRef,
        where('_clientID', '==', clientID),
        where('_locationID', 'in', locationIDs),
        where('_date', 'in', dates),
        where('_lastUpdated', '>', lastChecked),
      );
    }
  }

  // USERS FUNCTIONS

  public getUsersChanges<T>(clientID: string): Observable<UserSettings[] | unknown[]> {
    return new Observable((observer) => {
      const usersCollection = collection(this.firestore, 'users') as CollectionReference<T>;
      const q = query(usersCollection, where('clientID', '==', clientID));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const changes = snapshot.docChanges().map(change => ({ uid: change.doc.id, ...change.doc.data() }));
        observer.next(changes);
      }, (error) => {
        observer.error(new Error(`afs.getUsersChanges: users/${clientID}, ${this.error.toStr(error)}`));
      });

      return () => unsubscribe();
    });
  }

  public getPendingUsersChanges(clientID: string): Observable<PendingUser[] | unknown[]> {
    return new Observable<PendingUser[] | unknown[]>((observer) => {
      const colRef = collection(this.firestore, 'pendingUsers');
      const q = query(colRef, where('clientID', '==', clientID));

      const unsubscribe = onSnapshot(q, (querySnapshot: QuerySnapshot<DocumentData>) => {
        const pendingUsers: PendingUser[] = querySnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() } as PendingUser;
        });
        observer.next(pendingUsers);
      }, (error) => {
        observer.error(new Error(`afs.getPendingUsersChanges: users/${clientID}, ${this.error.toStr(error)}`));
      });

      return () => unsubscribe();
    });
  }

  // QUERY FUNCTIONS

  public async queryClients(): Promise<QuerySnapshot<DocumentData> | undefined> {
    try {
      return (await getDocs(collection(this.firestore, 'clients')));
    } catch (error) {
      console.error(`afs.queryClients: ${this.error.toStr(error)}`);
    }
  }

  public async queryTrends(clientID: string, locationID: string):
    Promise<QuerySnapshot<TrendsByHourPacked> | undefined> {

    try {
      const trendsQuery = query(collection(this.firestore, 'trends'),
        where('_clientID', '==', clientID),
        where('_locationID', '==', locationID),
      );
      return (await getDocs(trendsQuery)) as QuerySnapshot<TrendsByHourPacked>;
    } catch (error) {
      console.error(`afs.queryTrends: ${this.error.toStr(error)}`);
    }
  }

  public async queryUsers(): Promise<QuerySnapshot<DocumentData> | undefined> {
    try {
      return (await getDocs(collection(this.firestore, 'users')));
    } catch (error) {
      console.error(`afs.queryUsers: ${this.error.toStr(error)}`);
    }
  }

  public async queryClientUsers(clientID: string): Promise<QuerySnapshot<UserSettings> | undefined> {
    try {
      const clientUsersQuery = query(collection(this.firestore, 'users'),
        where('clientID', '==', clientID));
      return (await getDocs(clientUsersQuery)) as QuerySnapshot<UserSettings>;
    } catch (error) {
      console.error(`afs.queryClientUsers: ${this.error.toStr(error)}`);
    }
  }

  public async queryPendingUser(email: string):
    Promise<{ pendingUser: PendingUser | undefined, pendingUserID: string }> {

    try {
      const pendingUserQuery = query(collection(this.firestore, 'pendingUsers'),
        where('email', '==', email));
      const querySnapshot = await getDocs(pendingUserQuery);

      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0];
        return { pendingUser: doc.data() as PendingUser, pendingUserID: doc.id };
      } else {
        return { pendingUser: undefined, pendingUserID: '' };
      }
    } catch (error) {
      console.error(`afs.queryPendingUser: ${this.error.toStr(error)}`);
      return { pendingUser: undefined, pendingUserID: '' };
    }
  }

  // OTHER FUNCTIONS

  public async addCheckoutSession(uid: string, priceID: string, successURL: string, failURL: string,
    trialDaysLeft: number): Promise<DocumentReference<DocumentData>> {

    return await addDoc(collection(doc(this.firestore, 'users', uid), 'checkout_sessions'), {
      price: priceID,
      trial_from_plan: true,
      trial_end: this.dt.addDateStr(this.dt.now(), trialDaysLeft),
      billing_address_collection: 'auto',
      proration_behavior: 'always_invoice',
      success_url: successURL,
      cancel_url: failURL,
    });
  }
}
