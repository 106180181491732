import { Component } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { DtService } from '@services/dt.service';

@Component({
  selector: 'app-calendar-icon',
  templateUrl: './calendar-icon.component.html',
  styleUrl: './calendar-icon.component.scss',
  imports: [IonicModule],
})
export class CalendarIconComponent {
  public dayOfWeek = '';
  public date = '';

  constructor(
    private dt: DtService,
  ) {
    this.dayOfWeek = this.dt.format(this.dt.now(), 'ddd');
    this.date = this.dt.format(this.dt.now(), 'D');
  }
}
