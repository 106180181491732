import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Observable, Subject, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SignoutService {
  public signout$: Subject<void> = new Subject<void>();
  public isSigningOut = false;

  constructor() { }

  public next(): void {
    this.isSigningOut = true;
    this.signout$.next();
  }

  public complete(): void {
    this.signout$.complete();
  }

  public takeUntil<T>(destroyRef: DestroyRef): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) =>
      source.pipe(takeUntil(this.signout$), takeUntilDestroyed(destroyRef));
  }
}
