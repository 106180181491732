import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

import { IonicModule, MenuController } from '@ionic/angular';

import { CalendarIconComponent } from '@ion-components/calendar-icon/calendar-icon.component';

import { ColorsService } from '@services/colors.service';
import { DeviceService } from '@services/device.service';
import { LsService } from '@services/ls.service';

import { AppState } from '@state/app.state';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrl: './tabs.page.scss',
  imports: [
    NgOptimizedImage, IonicModule,
    CalendarIconComponent,
  ],
})
export class TabsPage {
  public tabSelected = 'dashboard';
  public insightsFontStyle = 'font-weight: normal';
  public todayFontStyle = 'font-weight: bold';
  public trendsFontStyle = 'font-weight: normal';

  constructor(
    public colors: ColorsService,
    public device: DeviceService,
    private appState: AppState,
    private ls: LsService,
    private menu: MenuController,
  ) { }

  public ionViewWillEnter(): void {
    void this.menu.enable(true);
  }

  public tabChange(tab: string): void {
    this.tabSelected = tab;
    this.insightsFontStyle = `font-weight: ${tab === 'insights' ? 'bold' : 'normal'}; ` +
      `color: ${tab === 'insights' ? 'white' : 'lightgray'} !important`;
    this.todayFontStyle = `font-weight: ${tab === 'dashboard' ? 'bold' : 'normal'}; ` +
      `color: ${tab === 'dashboard' ? 'white' : 'lightgray'} !important`;
    this.trendsFontStyle = `font-weight: ${tab === 'trends' ? 'bold' : 'normal'}; ` +
      `color: ${tab === 'trends' ? 'white' : 'lightgray'} !important`;

    this.appState.tabSelected = this.tabSelected;
    this.appState.refreshLocationFilter();
    this.appState.tabChange$.next(tab);

    this.ls.set('lastTab', this.tabSelected);
  }
}
