<ion-app dir="ltr">
  <ion-split-pane when="(min-width: 1130px)" content-id="main-content">
    <ion-menu
      class="app-sidemenu"
      contentId="main-content"
      (ionDidOpen)="updateMenuState($event.type)"
      (ionDidClose)="updateMenuState($event.type)"
    >
      <ion-header>
        <ion-toolbar>
          <ion-row class="logo-wrapper">
            <ion-col size="6" offset="3">
              <div class="logo">
                <img
                  class="sprk-logo"
                  ngSrc="assets/logos/dark-256.webp"
                  width="256"
                  height="256"
                  alt="Sprk Logo"
                  priority
                />
              </div>
            </ion-col>
            <ion-col class="close-wrapper" size="2" offset="1">
              <ion-menu-toggle>
                <ion-button class="close-button" fill="clear">
                  <ion-icon slot="icon-only" name="close-outline"></ion-icon>
                </ion-button>
              </ion-menu-toggle>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="8" offset="2">
              <div class="app-info-wrapper">
                <h6 class="app-version">Version {{ version }} {{ env.dev ? "DEV" : "" }}</h6>
                <h6 class="app-copyright">© Copyright {{ dt.currentYear }}. All rights reserved.</h6>
              </div>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <!-- Navigation for dashboard pages -->
        @if (!device.smallerThanLaptop) {
          <div>
            <ion-item-divider></ion-item-divider>
            <ion-list>
              <ion-menu-toggle auto-hide="false">
                <ion-item (click)="routerLink('/app/insights')">
                  <img
                    class="tab-icon"
                    ngSrc="assets/icons/tabs/insights.webp"
                    width="85"
                    height="85"
                    alt="Insights icon"
                  />
                  <ion-label class="menu-label">Insights</ion-label>
                </ion-item>
                <ion-item (click)="routerLink('/app/dashboard')">
                  <app-calendar-icon></app-calendar-icon>
                  <ion-label class="menu-label">Dashboard</ion-label>
                </ion-item>
                <ion-item (click)="routerLink('/app/trends')">
                  <img
                    class="tab-icon"
                    ngSrc="assets/icons/tabs/trends.webp"
                    width="69"
                    height="69"
                    alt="Trends icon"
                  />
                  <ion-label class="menu-label">Trends</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>
          </div>
        }

        <ion-list>
          @if (
            userState.canView["insights"] ||
            userState.canView["settings"] ||
            userState.canView["catalog"] ||
            userState.canView["profit"]
          ) {
            <ion-item-divider [class]="device.smallerThanLaptop ? 'first-divider' : ''"></ion-item-divider>
          }
          <ion-menu-toggle auto-hide="false">
            @if (userState.canView["insights"]) {
              <ion-item (click)="routerLink('/configure')" [disabled]="!active.isConnected">
                <ion-icon class="configure-icon" name="build-outline"></ion-icon>
                <ion-label class="menu-label">Customize Insights</ion-label>
              </ion-item>
            }
            @if (userState.canView["settings"]) {
              <ion-item (click)="routerLink('/settings')" [disabled]="!active.isConnected">
                <ion-icon name="location-outline"></ion-icon>
                <ion-label class="menu-label">Locations / Connections</ion-label>
              </ion-item>
            }
            @if (userState.canView["catalog"]) {
              <ion-item (click)="routerLink('/catalog')" [disabled]="!active.isConnected">
                <ion-icon name="pricetags-outline"></ion-icon>
                <ion-label class="menu-label">Categories / Products</ion-label>
              </ion-item>
            }
            @if (userState.canView["profit"]) {
              <ion-item (click)="routerLink('/profit')" [disabled]="!active.isConnected">
                <ion-icon name="cash-outline"></ion-icon>
                <ion-label class="menu-label">Profitability Analysis</ion-label>
              </ion-item>
            }
          </ion-menu-toggle>
        </ion-list>

        <!-- Navigation for account/preferences/signout activities -->
        <ion-list>
          <ion-item-divider></ion-item-divider>
          <ion-menu-toggle auto-hide="false">
            <ion-item (click)="routerLink('/billing')" [disabled]="!active.isConnected">
              <ion-icon name="person-circle-outline"></ion-icon>
              <ion-label class="menu-label">
                {{ userState.canView["billing"] && !userState.demo ? "Profile & Billing" : "Profile" }}
              </ion-label>
            </ion-item>
            <ion-item (click)="routerLink('/preferences')" [disabled]="!active.isConnected">
              <ion-icon name="settings-outline"></ion-icon>
              <ion-label class="menu-label">Preferences</ion-label>
            </ion-item>
            @if (userState.canView["users"] && !userState.demo) {
              <ion-item (click)="routerLink('/users')" [disabled]="!active.isConnected">
                <ion-icon name="people-outline"></ion-icon>
                <ion-label class="menu-label">Users</ion-label>
              </ion-item>
            }
            <ion-item (click)="signOut()">
              <ion-icon name="log-out-outline"></ion-icon>
              <ion-label class="menu-label">Sign Out</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <!-- Navigation for support-related pages -->
        <ion-list>
          <ion-item-divider></ion-item-divider>
          <ion-menu-toggle auto-hide="false">
            @if (update.updateAvailable) {
              <ion-item (click)="update.reload()" [disabled]="!active.isConnected">
                <ion-icon name="refresh-outline"></ion-icon>
                <ion-label class="menu-label">Update to latest version</ion-label>
              </ion-item>
            }
            @if (device.mobile && !a2hs.isInstalled) {
              <ion-item (click)="a2hs.showInstallPrompt()">
                <ion-icon name="share-outline"></ion-icon>
                <ion-label class="menu-label">Add App to Home Screen</ion-label>
              </ion-item>
            }
            <ion-item (click)="routerLink('/releases')">
              <ion-icon name="list-circle-outline"></ion-icon>
              <ion-label class="menu-label">Latest Updates</ion-label>
              @if (env.highlightReleaseNotes) {
                <ion-img class="new-icon" src="assets/icons/new.webp" alt="New icon"></ion-img>
              }
            </ion-item>
            <ion-item (click)="routerLink('/help')" [disabled]="!active.isConnected">
              <ion-icon name="help-circle-outline"></ion-icon>
              <ion-label class="menu-label">Help</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <!-- Main app content get's rendered in this router-outlet -->
    <ion-router-outlet id="main-content" [animated]="false"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
