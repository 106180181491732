import { FirebaseApp, initializeApp, setLogLevel } from 'firebase/app';
import { environment } from '@environments/environment';

const firebaseApp: FirebaseApp = (() => {
  try {
    return initializeApp(environment.firebase);
  } catch (error) {
    console.error('Firebase initialization error:', error);
    throw error;
  }
})();

setLogLevel('error');

export { firebaseApp };
