import { DestroyRef, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, filter, firstValueFrom, Subscription, take } from 'rxjs';

import { nanoid } from 'nanoid';

import { AfsService } from '@services/afs.service';
import { ChannelsService } from '@services/channels.service';
import { DtService } from '@services/dt.service';
import { FunctionsService } from '@services/functions.service';
import { SignoutService } from '@services/signout.service';
import { SourcesService } from '@services/sources.service';
import { UtilitiesService } from '@services/utilities.service';

import { CLIENT_SETTINGS_VERSION, ClientSettings, ConnectionSettings, LocationSettings }
  from '@shared/settings.interface';
import { RequestDeleteLocation, RequestDeleteConnection } from '@shared/request.interface';

import { AppState } from '@state/app.state';
import { UserState } from '@state/user.state';

@Injectable({ providedIn: 'root' })
export class SettingsService implements OnDestroy {
  public validatedSettings$ = new BehaviorSubject<ClientSettings>(new ClientSettings());
  public rawSettings$ = new BehaviorSubject<ClientSettings>(new ClientSettings());
  public refreshNeeded$ = new BehaviorSubject<boolean>(false);

  public locationTableIndex = -1;
  public locationTableIndex$ = new BehaviorSubject<number>(-1);
  public connectionTableIndex = -1;
  public connectionTableIndex$ = new BehaviorSubject<number>(-1);

  public errors: string[] = [];
  public hasAPIConnection = false;

  public servchgs: { [key: string]: boolean; } = {};
  public commissions: { [key: string]: number; } = {};
  public mapChannels: { [key: string]: boolean; } = {};

  private subSettings: Subscription | undefined;
  private subSignout: Subscription;

  constructor(
    private afs: AfsService,
    private appState: AppState,
    private channels: ChannelsService,
    private destroyRef: DestroyRef,
    private dt: DtService,
    private functions: FunctionsService,
    private router: Router,
    private signout: SignoutService,
    private sources: SourcesService,
    private userState: UserState,
    private util: UtilitiesService,
  ) {
    this.processRawSettings();

    this.subSignout = this.signout.signout$
      .subscribe(() => {
        this.rawSettings$.next(new ClientSettings());
        this.validatedSettings$.next(new ClientSettings());
      });
  }

  ngOnDestroy(): void {
    if (this.subSignout) this.subSignout.unsubscribe();
    if (this.subSettings) this.subSettings.unsubscribe();
  }

  public processRawSettings(): void {
    this.rawSettings$
      .pipe(this.signout.takeUntil(this.destroyRef))
      .subscribe({
        next: async (settings) => {
          // Force update if data version is higher than app version
          if (settings._version > CLIENT_SETTINGS_VERSION) {
            await this.router.navigate(['update'], { state: { internalRequest: true } });
          }

          if (settings?.locations?.length) {
            if (this.locationTableIndex === -1) {
              this.updateLocationTableIndex();
            }

            this.assignLocationSortOrder(settings.locations);

            this.hasAPIConnection = !!(settings.locations ?? []).find(location =>
              (location.connections ?? []).find(connection => connection.token &&
                (connection.sourceID === 'CL' ||
                  (connection.sourceID === 'SQ' && connection.custom?.merchantLocationID))));

            const validatedSettings = this.validateSettings(settings);
            await this.appState.updateLocations(settings, validatedSettings);
            if (this.settingsChanged(this.validatedSettings$.value, validatedSettings)) {
              this.validatedSettings$.next(validatedSettings);

              if (validatedSettings.locations?.length) {
                await this.sources.update(validatedSettings);
                this.appState.historyComplete$.next(validatedSettings.locations[0].historyComplete ?? false);
                this.updateServChgs();
                this.updateCommissions();
                this.updateMapChannels();
              }
            }
          }
        },
        error: (error) => console.error(`APP settings.processRawSettings, Error: ${error}`),
      });
  }

  public getClientSettings$(clientID: string): void {
    if (this.subSettings) this.subSettings.unsubscribe();

    this.subSettings = this.afs.getValueChanges<ClientSettings>('clients', clientID)
      .subscribe({
        next: async rawSettings => {
          // Convert Firestore timestamps to Dates
          let settings: ClientSettings = this.util.parseTimestampToDate(rawSettings);

          if (settings) {
            // Migrate data to latest version if necessary
            if (!settings._version || settings._version < CLIENT_SETTINGS_VERSION) {
              settings = await this.migrateSettings(settings);
            }

            this.rawSettings$.next(settings);

            if (settings.license.status === 'inactive' && !this.router.url.startsWith('/billing')) {
              await this.router.navigate(['billing'], { state: { internalRequest: true } });
            }
          }
        },
        error: (error) => console.error(`APP settings.getClientSettings of ${clientID}, Error: ${error}`),
      });

    this.signout.signout$.pipe(take(1)).subscribe(() => {
      if (this.subSettings) this.subSettings.unsubscribe();
    });
  }

  public getAuthLocation(locations: LocationSettings[], authIndex: number): LocationSettings | undefined {
    if (!locations?.length) return undefined;

    const authLocations = locations
      .filter(location => !this.userState.user.locationIDs.length ||
        this.userState.user.locationIDs.includes(location.locationID));

    return authIndex < authLocations.length ? authLocations[authIndex] : undefined;
  }

  public getAuthLocations(locations: LocationSettings[]): LocationSettings[] | undefined {
    if (!locations?.length) return undefined;
    return locations
      .filter(location => !this.userState.user.locationIDs.length ||
        this.userState.user.locationIDs.includes(location.locationID));
  }

  public assignLocationSortOrder(locations: LocationSettings[]): void {
    const sortedLocations = locations
      .sort((a, b) => (Number(b.active) - Number(a.active)) || ((a.sortOrder ?? 0) - (b.sortOrder ?? 0)));

    let counter = 0;
    for (const location of sortedLocations) {
      location.sortOrder = counter;
      counter++;
    }
  }

  private async migrateSettings(settings: ClientSettings): Promise<ClientSettings> {
    // In settings V6, the graph color palette was changed; clear it and start over
    if (!settings._version || settings._version < 19) {  // 19 = V6
      settings.channels = [];
      await this.sources.assignChannelColors(settings, false);
    }

    settings._version = CLIENT_SETTINGS_VERSION;
    await this.afs.setDocument<ClientSettings>('clients', this.appState.clientID, settings, { merge: false });
    window.location.reload();
    return settings;
  }

  private validateSettings(settings: ClientSettings): ClientSettings {
    let activeLocations: LocationSettings[] = [];
    this.errors = [];

    if (settings.license?.status === 'inactive') {
      this.errors.push('NOT_LICENSED');
    }
    if (!settings.locations?.length) {
      this.errors.push('NO_LOCATIONS');
      console.error(`APP validateSettings: No locations for uid ${this.userState.user.uid}`);
    } else {
      activeLocations = settings.locations.filter(location => location.active);
      const validLocations = settings.locations.filter(location => {
        const validConnections = location.connections ?
          location.connections.filter(connection => connection.token) : [];
        return location.active && (location.minDate || validConnections.length);
      });
      if (!validLocations.length) this.errors.push('NO_VALID_LOCATIONS');
    }

    return {
      _version: settings?._version ?? CLIENT_SETTINGS_VERSION,
      _connectionMaps: settings?._connectionMaps ?? [],
      _timezones: settings?._timezones ?? [],
      license: this.util.deepClone(settings.license),
      businessName: settings?.businessName ?? '',
      startDayOfWeek: settings?.startDayOfWeek ?? 1,
      locations: this.util.deepClone(activeLocations),
      channels: settings ? this.util.deepClone(settings.channels) : [],
    };
  }

  private settingsChanged(oldSettings: ClientSettings, newSettings: ClientSettings): boolean {
    const oldSettingsClone = this.util.deepClone(oldSettings);
    const newSettingsClone = this.util.deepClone(newSettings);

    // Remove lastChecked field before checking for differences
    for (const location of oldSettingsClone.locations) {
      if (location.connections?.length) {
        for (const connection of location.connections) {
          delete connection.lastChecked;
        }
      }
    }
    for (const location of newSettingsClone.locations) {
      if (location.connections?.length) {
        for (const connection of location.connections) {
          delete connection.lastChecked;
        }
      }
    }
    return this.util.isDiffDeep(oldSettingsClone, newSettingsClone);
  }

  public getRawSettings(): Promise<ClientSettings> {
    return firstValueFrom(this.rawSettings$
      .pipe(
        filter(value => value !== null && value !== undefined && value.locations?.length > 0),
      ));
  }

  public getValidatedSettings(): Promise<ClientSettings> {
    return firstValueFrom(this.validatedSettings$);
  }

  public getPath(): string {
    return this.appState.clientID ? `clients/${this.appState.clientID}` : '';
  }

  public async addLocation(): Promise<void> {
    // Create new record using just a unique ID from nanoid
    const newLocationID = nanoid(9);
    const newLocation: LocationSettings = {
      locationID: newLocationID,
      active: false,
      // TODO: Get timezone from POS in backend
      timezone: this.dt.localTimezone(),
      dayEndHour: 0,
      connections: [],
      channels: [],
    };

    const settings = await this.afs.getDocument<ClientSettings>('clients', this.appState.clientID);
    if (settings) {
      // TODO: Get timezone from POS in backend
      const timezones = new Set(settings._timezones);
      timezones.add(this.dt.localTimezone());

      // Update clients document with location and updated timezones
      settings._timezones = [...timezones];
      newLocation.sortOrder = settings.locations.length;
      settings.locations.push(newLocation);
      await this.afs.setDocument<ClientSettings>('clients', this.appState.clientID, settings, { merge: false });
      this.updateLocationTableIndex(newLocationID);
      await this.appState.updateLocations(settings, this.validatedSettings$.value);
    }
  }

  public async deleteLocation(locationID: string): Promise<void> {
    if (!locationID) return;

    const settings = await this.afs.getDocument<ClientSettings>('clients', this.appState.clientID);
    if (!settings) return;

    const location = settings.locations.find(location => location.locationID === locationID);
    if (!location) return;

    // Delete all data for connectionID
    const deleteLocationMessage: RequestDeleteLocation = {
      clientID: this.appState.clientID,
      locationID: location.locationID,
      timezone: location.timezone,
      latitude: location.latitude ?? 0,
      longitude: location.longitude ?? 0,
      dayEndHour: location.dayEndHour,
      country: location.country ?? '',
      stateProvince: location.stateProvince ?? '',
      postalCode: location.postalCode ?? '',
      minDate: location.minDate ?? '',
    };
    await this.functions.deleteLocation(deleteLocationMessage);

    this.updateLocationTableIndex();
  }

  public async updateTimezones(): Promise<void> {
    // Add delay to allow UI to finish updating client record
    await this.util.sleep(3);

    const settings = await this.afs.getDocument<ClientSettings>('clients', this.appState.clientID);
    if (settings) {
      // Rebuild timezones array for all locations
      const timezones = new Set<string>();
      for (let i = 0; i < settings.locations?.length; i++) {
        timezones.add(settings.locations[i].timezone);
      }

      // Update clients document with location and updated timezones
      settings._timezones = [...timezones];
      await this.afs.setDocument<ClientSettings>('clients', this.appState.clientID, settings, { merge: false });
    }
  }

  public async addConnection(sourceID: string, locationID: string): Promise<string> {
    // Create new record using just a unique ID from nanoid
    const newConnectionID = nanoid(9);
    const newConnection: ConnectionSettings = {
      active: false,
      sourceID: sourceID,
      connectionID: newConnectionID,
      lastChecked: this.dt.oldestDate,
    };

    if (this.sources.getSetting(sourceID, 'type') === 'email') {
      newConnection.emailVerified = false;
    }

    const settings = await this.afs.getDocument<ClientSettings>('clients', this.appState.clientID);
    if (!settings) return '';

    const location = settings.locations.find(location => location.locationID === locationID);
    if (!location) return '';

    if (location.connections) {
      location.connections.push(newConnection);
    } else {
      location.connections = [newConnection];
    }

    const channelIDs = this.sources.getSetting(sourceID, 'channelIDs') as string[];

    // For new channels, pick a channel color at client level
    for (const channelID of channelIDs) {
      if (!settings.channels?.find(channel => channel.channelID === channelID)) {
        settings.channels.push({
          channelID: channelID,
          color: this.channels.setColor(channelID),
        });
      }

      // For new channels, set default values at location level
      if (!location.channels.find(channel => channel.channelID === channelID)) {
        location.channels.push({
          channelID: channelID,
          servchgs: false,
          commission: 0,
          mapChannel: this.channels.getDefaultMapChannel(channelID),
        });
      }
    }
    await this.afs.setDocument<ClientSettings>('clients', this.appState.clientID, settings, { merge: false });

    this.updateConnectionTableIndex();
    this.updateConnectionTableIndex(newConnectionID);

    return newConnectionID;
  }

  public async deleteConnection(): Promise<void> {
    const settings = this.rawSettings$.value;
    const location = this.getAuthLocation(settings?.locations, this.locationTableIndex);
    const connection = location?.connections?.[this.connectionTableIndex];

    if (!settings || !location || !connection) return;

    const { remainingChannels, remainingChannelsForLocation } =
      this.getRemainingChannels(settings, location.locationID, connection.connectionID);

    // If last remaining channel is hidden, force it to show
    if (remainingChannelsForLocation.length === 1) {
      this.channels.toggleVisible(remainingChannelsForLocation[0], true);
    }

    // Release color if channel is no longer used
    const channelIDs = this.sources.getSetting(connection.sourceID, 'channelIDs') as string[];
    for (const channelID of channelIDs) {
      if (!remainingChannels.includes(channelID)) {
        const channelSettings = settings?.channels?.find(channel => channel.channelID === channelID);
        if (channelSettings) {
          this.channels.releaseColor(channelID, channelSettings.color);
        }
      }
    }

    const deleteConnectionMessage: RequestDeleteConnection = {
      sourceID: connection.sourceID,
      clientID: this.appState.clientID,
      locationID: location.locationID,
      connectionID: connection.connectionID,
      timezone: location.timezone,
      latitude: location.latitude ?? 0,
      longitude: location.longitude ?? 0,
      dayEndHour: location.dayEndHour,
      country: location.country ?? '',
      stateProvince: location.stateProvince ?? '',
      postalCode: location.postalCode ?? '',
      minDate: location.minDate ?? '',
    };
    await this.functions.deleteConnection(deleteConnectionMessage);

    this.updateConnectionTableIndex();
  }

  private getRemainingChannels(settings: ClientSettings, locationID: string,
    connectionID: string): { remainingChannels: string[], remainingChannelsForLocation: string[] } {

    const remainingChannels = new Set<string>();
    const remainingChannelsForLocation = new Set<string>();

    for (const location of settings.locations) {
      for (const connection of location.connections) {
        if (connection.connectionID !== connectionID) {
          const channelIDs = this.sources.getSetting(connection.sourceID, 'channelIDs') as string[];
          for (const channelID of channelIDs) {
            remainingChannels.add(channelID);
            if (location.locationID === locationID) {
              remainingChannelsForLocation.add(channelID);
            }
          }
        }
      }
    }
    return {
      remainingChannels: [...remainingChannels],
      remainingChannelsForLocation: [...remainingChannelsForLocation],
    };
  }

  public updateLocationTableIndex(locationID = '', connectionID = ''): void {
    let index = 0;
    if (locationID) {
      const locations = this.getAuthLocations(this.rawSettings$.value?.locations);
      index = !locations ? -1 : locations?.findIndex(location => location.locationID === locationID);
    }

    this.locationTableIndex = index;
    this.updateConnectionTableIndex(connectionID);
    this.locationTableIndex$.next(index);
  }

  public updateConnectionTableIndex(connectionID = ''): void {
    const location = this.getAuthLocation(this.rawSettings$.value?.locations, this.locationTableIndex);
    if (!location?.connections?.length) {
      this.connectionTableIndex = -1;
      this.connectionTableIndex$.next(-1);
    } else {
      location.connections?.forEach((connection, index) => {
        if ((connectionID === '' && index === 0) || (connection.connectionID === connectionID)) {
          this.connectionTableIndex = index;
          this.connectionTableIndex$.next(index);
        }
      });
    }
  }

  public updateServChgs(): boolean {
    const prevServChgs = this.util.deepClone(this.servchgs);
    const location = this.getAuthLocation(this.validatedSettings$.value.locations,
      this.appState.activeLocationIndex);
    if (location?.active && location?.channels?.length) {
      for (const channel of location.channels) {
        this.servchgs[channel.channelID] = channel.servchgs ?? false;
      }
    }
    return this.util.isDiffShallow(prevServChgs, this.servchgs);
  }

  public updateCommissions(): boolean {
    const prevCommissions = this.util.deepClone(this.commissions);
    const location = this.getAuthLocation(this.validatedSettings$.value.locations,
      this.appState.activeLocationIndex);
    if (location?.active && location?.channels?.length) {
      for (const channel of location.channels) {
        this.commissions[channel.channelID] = (channel.commission ?? 0) / 100;
      }
    }
    return this.util.isDiffShallow(prevCommissions, this.commissions);
  }

  public updateMapChannels(): boolean {
    const prevMapChannels = this.util.deepClone(this.mapChannels);
    const location = this.getAuthLocation(this.validatedSettings$.value.locations,
      this.appState.activeLocationIndex);
    if (location?.active && location?.channels?.length) {
      for (const channel of location.channels) {
        this.mapChannels[channel.channelID] = channel.mapChannel ?? false;
      }
    }
    return this.util.isDiffShallow(prevMapChannels, this.mapChannels);
  }

  public locationCount(): number {
    return this.getAuthLocations(this.rawSettings$.value?.locations)?.length ?? 0;
  }

  public activeLocationCount(): number {
    const locations = this.getAuthLocations(this.rawSettings$.value?.locations);
    return !locations ? 0 : locations.reduce((activeCount, location) => activeCount + (+location.active), 0);
  }

  public connectionCount(): number {
    const locations = this.getAuthLocation(this.rawSettings$.value?.locations, this.locationTableIndex);
    return locations?.connections?.length ?? 0;
  }

  public activeConnectionCount(): number {
    const location = this.getAuthLocation(this.rawSettings$.value?.locations, this.locationTableIndex);
    const connections = location?.connections;
    return !connections ? 0 : connections.reduce((activeCount, connection) => activeCount + (+connection.active), 0);
  }

  public activeGoogleConnection(): boolean {
    const location = this.getAuthLocation(this.rawSettings$.value?.locations, this.locationTableIndex);
    const connections = location?.connections;
    return !connections ? false : connections.filter(connection =>
      connection.sourceID === 'GO' && connection.id && connection.token && connection.active).length > 0;
  }

  public getLicensedLocationID(): string {
    return (this.rawSettings$.value?.locations ?? [])
      .find(l => (l.connections ?? [])
        .find(c => c.id === this.rawSettings$.value.license.licenseID))?.locationID ?? '';
  }

}
