/* eslint-disable @typescript-eslint/no-empty-function */

import { enableProdMode, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { IonicModule } from '@ionic/angular';

import { MatNativeDateModule } from '@angular/material/core';

import { AppComponent } from '@app/app.component';
import { provideSentryErrorHandler } from '@app/app.sentry';
import { routes } from '@app/app.routes';

import { AfsService } from '@services/afs.service';
import { AuthService } from '@services/auth.service';
import { DeviceService } from '@services/device.service';
import { firebaseApp } from '@services/firebase.service';

import { environment } from '@environments/environment';

const isTest = window?.location?.host?.includes('localhost') ?? false;

if (!isTest) {
  enableProdMode();
}

if (window && environment.production && environment.firebase.projectId === 'manage-my-business') {
  window.console.log = () => { };
  window.console.time = () => { };
  window.console.timeEnd = () => { };
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    importProvidersFrom(
      IonicModule.forRoot(),
      MatNativeDateModule,
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: !isTest }),
    ),
    provideAnimationsAsync(),
    provideSentryErrorHandler(),
    { provide: 'FIREBASE_APP', useValue: firebaseApp },
    provideAppInitializer(() => inject(AfsService).initialize()),
    provideAppInitializer(() => inject(AuthService).initialize()),
    provideAppInitializer(() => inject(DeviceService).initialize()),
  ],
}).catch(error => console.error(`Bootstrap error: ${error}`));