import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LsService {

  // Local-only fields stored in LocalStorage but not User table
  // Local-device fields: clearCacheID, redirect, showInstallMessage
  // Load-time requirement: colorMode, colorScheme, defaultSignin, initialTab, lastTab

  public get(key: string, value = ''): string {
    const result = window.localStorage ? window.localStorage.getItem(key) : value;
    return result ?? value;
  }

  public set(key: string, value: string): void {
    if (window.localStorage) {
      window.localStorage.setItem(key, value);
    }
  }

  public remove(key: string): void {
    if (window.localStorage) {
      window.localStorage.removeItem(key);
    }
  }

}
